const { axiosCreate } = require('./Api')
const { getCart } = require('./CartService')
import store from '../state/store'

async function login({ username, password }) {
  try {
    const result = await axiosCreate.get('public/registerUser', {
      auth: {
        username: username,
        password: password
      }
    })

    if (result.data.UserName && result.data.Accounts) {
      const accounts = result.data.Accounts.Collection

      let activeAcounts = []

      for (let account of accounts) {
        if (account.IsCustomerActive === false) continue
        activeAcounts.push(account)
      }

      // If there are multiple accounts and none are available for login, the warning with the hightest priority is shown.
      if (accounts.length >= 1 && activeAcounts.length < 1) {
        let statusCodes = []

        for (let account of accounts) {
          statusCodes.push(account.CUST_Status)
        }

        if (statusCodes.includes('5. Tijdelijk geschorst'))
          return 'Organisation inactive'
        if (statusCodes.includes('1. Nieuw')) return 'newOrganisation'
        if (statusCodes.includes('2. In behandeling'))
          return 'processingOrganisation'
        if (statusCodes.includes('6. Niet erkend'))
          return 'invalidatedOrganisation'
      }

      // Check if we actually are logged in
      store.commit('setBearerToken', {
        token: `Bearer ${result.data.Token.Acces_token}`
      })

      await store.commit('setUser', {
        user: result.data,
        contactID: activeAcounts[0].ContactID
      })
      await getCart()
    }

    return result
  } catch (error) {
    return error.response
  }
}

async function requestPasswordReset({ email }) {
  const result = await axiosCreate.post('public/registerUser/lostpassword', {
    Email: email,
    LanguageID: 'NL',
    URL: `${process.env.VUE_APP_FRONTEND_URL}/nieuw-wachtwoord?code=<code>`
  })
  return result
}

async function deleteSub({ subUserName, subUserContactID }) {
  const result = await axiosCreate.delete(
    `public/registerUser/${store.getters.contactID}/subaccounts?subUserName=${subUserName}&contactID=${subUserContactID}`,
    {
      headers: { authorization: store.getters.token }
    }
  )
  return result
}

async function setNewPassword({ code, password, confirmPassword }) {
  const result = axiosCreate.post('public/registerUser/resetpassword', {
    Code: code,
    Password: password,
    ConfirmPassword: confirmPassword
  })
  return result
}

async function register({
  username,
  email,
  password,
  street,
  houseNumber,
  postcode,
  city,
  tel,
  companyName,
  vatid,
  website,
  targetAudience,
  firstname,
  lastname,
  CONTACT_email,
  CONTACT_street,
  CONTACT_houseNumber,
  CONTACT_postcode,
  CONTACT_city,
  CONTACT_tel,
  CONTACT_opmerking,
  invoiceCompanyName,
  invoiceStreet,
  invoiceHouseNumber,
  invoicePostcode,
  invoiceCity,
  invoiceEmail,
  invoiceTel
} = {}) {
  const result = await axiosCreate.post('public/registerUser', {
    UserName: username,
    Email: email,
    UserEmail: email,
    CONTACT_Email: CONTACT_email,
    Password: password,
    ConfirmPassword: password,
    FirstName: firstname,
    LastName: lastname,
    Tel: tel,
    CONTACT_Tel: CONTACT_tel,
    CompanyName: companyName,
    Street: street,
    ReceiverName: `${firstname} ${lastname}`,
    CUST_Street: CONTACT_street,
    HouseNumber: houseNumber,
    CUST_HouseNumber: CONTACT_houseNumber,
    ZipCode: postcode,
    CUST_ZipCode: CONTACT_postcode,
    City: city,
    CUST_City: CONTACT_city,
    VATNo: vatid,
    LanguageID: 'NL',
    Website: website,
    ConfirmURL: `${process.env.VUE_APP_FRONTEND_URL}/account-aangemaakt/`,
    Salutation: '',
    CUST_Recognition: CONTACT_opmerking,
    CUST_TargetAudience: targetAudience,
    InvoiceCompanyName: invoiceCompanyName,
    InvoiceTel: invoiceTel,
    InvoiceCity: invoiceCity,
    InvoiceEmail: invoiceEmail,
    InvoiceZipCode: invoicePostcode,
    InvoiceHouseNumber: invoiceHouseNumber,
    InvoiceStreet: invoiceStreet
  })
  return result
}

async function confirmUser({ code }) {
  const result = await axiosCreate.post(
    'public/registerUser/confirmuser',
    null,
    {
      params: {
        code
      }
    }
  )
  return result
}

async function registerWithFiles({ formData } = {}) {
  const result = await axiosCreate.post(
    'public/registerUser/files',

    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  return result
}

async function newOrganizationWithFiles({ formData } = {}) {
  const result = await axiosCreate.post(
    'public/registerUser/accounts/files',

    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        authorization: store.getters.token
      }
    }
  )
  return result
}

async function getSubs() {
  const result = await axiosCreate.get(
    `public/registerUser/${store.getters.contactID}/subaccounts`,
    {
      headers: { authorization: store.getters.token }
    }
  )

  store.commit('setAccounts', { accounts: result.data.Collection })
  return result.data.Collection
}

async function newSub({
  email,
  password,
  confirmPassword,
  street,
  city,
  houseNumber,
  postcode,
  tel,
  firstname,
  lastname
}) {
  const result = await axiosCreate.post(
    `public/registerUser/${store.getters.contactID}/subaccounts`,
    {
      UserEmail: email,
      UserName: email,
      Password: password,
      ConfirmPassword: confirmPassword,
      Email: email,
      ConfirmURL: `${process.env.VUE_APP_FRONTEND_URL}/account-aangemaakt/`,
      FirstName: firstname,
      LastName: lastname,
      CUST_Street: street,
      CUST_HouseNumber: houseNumber,
      CUST_ZipCode: postcode,
      MobilePhone: tel,
      CUST_City: city
    },
    {
      headers: { authorization: store.getters.token }
    }
  )
  return result
}

async function modifySub({
  email,
  password,
  confirmPassword,
  street,
  city,
  houseNumber,
  postcode,
  tel,
  firstname,
  lastname,
  contactID,
  userName
}) {
  const result = await axiosCreate.patch(
    `public/registerUser/${store.getters.contactID}/subaccounts?subUserName=${userName}&contactID=${contactID}`,
    {
      ContactID: contactID,
      UserEmail: email,
      Password: password,
      ConfirmPassword: confirmPassword,
      Email: email,
      FirstName: firstname,
      LastName: lastname,
      CUST_Street: street,
      CUST_HouseNumber: houseNumber,
      CUST_ZipCode: postcode,
      MobilePhone: tel,
      CUST_City: city
    },
    {
      headers: { authorization: store.getters.token }
    }
  )
  return result
}
module.exports = {
  login,
  register,
  getSubs,
  newSub,
  modifySub,
  requestPasswordReset,
  setNewPassword,
  registerWithFiles,
  confirmUser,
  deleteSub,
  newOrganizationWithFiles
}
