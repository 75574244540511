<template>
  <b-container class="page-container">
    <Breadcrumbs
      :bread-crumbs="breadCrumbs"
      :currentpage="
        `Gegevens ${subUser.FirstName} ${subUser.LastName} bewerken`
      "
    />
    <b-form @submit.prevent="updateSub">
      <h1>Gegevens {{ subUser.FirstName }} {{ subUser.LastName }} bewerken</h1>
      <b-alert show variant="warning"
        ><font-awesome-icon icon="exclamation-circle" />Als je je e-mailadres of
        wachtwoord wijzigt, wijzigt dit ook mee voor andere profielen onder je
        gebruikersnaam. Let op, je oud e-mailadres blijft wel je gebruikersnaam
        om in te loggen.</b-alert
      >

      <b-row>
        <b-col sm="12" lg="8">
          <b-card>
            <h4>GEGEVENS VAN DE PERSOON BEWERKEN</h4>

            <b-row>
              <b-col cols="6" class="pb-3">
                <label>Voornaam</label>
                <b-form-input v-model="form.firstname" disabled></b-form-input>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>Achternaam</label>
                <b-form-input v-model="form.lastname" disabled></b-form-input>
              </b-col>
              <b-col cols="9" class="pb-3">
                <label>Straatnaam<span class="text-danger">*</span></label>
                <b-form-input v-model="form.street" required></b-form-input>
              </b-col>
              <b-col cols="3">
                <label>Huisnr.<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.houseNumber"
                  required
                ></b-form-input>
              </b-col>
              <b-col cols="4" class="pb-3">
                <label>Postcode<span class="text-danger">*</span></label>
                <b-form-input
                  v-model="form.postcode"
                  required
                  maxlength="4"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength); javascript: if (isNaN(this.value)) this.value = this.value.substring(0, this.value.length - 1);"
                ></b-form-input>
              </b-col>
              <b-col cols="8">
                <label>Gemeente<span class="text-danger">*</span></label>
                <b-form-input v-model="form.city" required></b-form-input>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>E-mailadres<span class="text-danger">*</span></label>
                <b-form-input v-model="form.email" required></b-form-input>
              </b-col>
              <b-col cols="6">
                <label>Telefoonnummer<span class="text-danger">*</span></label>
                <b-form-input v-model="form.tel" required></b-form-input>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
              <b-col cols="12">
                <b-form-text id="password-help-block">
                  <p>Een nieuwe wachtwoord moet:</p>
                  <ul>
                    <li>Minimaal 8 karakters lang zijn</li>
                    <li>Minimaal één hoofdletter bevatten (A t/m Z)</li>
                    <li>Minimaal één kleine letter bevatten (a t/m z)</li>
                    <li>Minimaal één cijfer bevatten (0 t/m 9)</li>
                    <li>Minimaal één speciale teken bevatten (!@#$%&)</li>
                  </ul>
                </b-form-text>
              </b-col>
              <b-col cols="6" class="pb-3">
                <label>Wachtwoord<span class="text-danger"></span></label>
                <b-form-input
                  v-model="form.password"
                  type="password"
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <label
                  >Herhaal wachtwoord<span class="text-danger"></span
                ></label>
                <b-form-input
                  v-model="form.confirmPassword"
                  type="password"
                  :state="passwordConfirmState"
                ></b-form-input>
              </b-col>
              <b-col v-if="!editingSelf && isKeyUser" cols="12">
                <b-alert v-if="warning" show variant="warning" class="mt-3"
                  >{{ warning }}
                </b-alert>
                <b-button variant="primary" @click="deleteContact"
                  >Verwijder contactpersoon</b-button
                >
              </b-col>
            </b-row>
          </b-card>

          <b-card class="mt-5">
            <b-row>
              <b-col>
                <h2 class="pt-2">Bevestigen?</h2>
              </b-col>
              <b-col class="text-right">
                <b-button variant="primary" type="submit"
                  >Wijzigingen opslaan
                  <font-awesome-icon
                    v-if="processing"
                    class="fa-spin"
                    far
                    icon="spinner"
                /></b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { modifySub, deleteSub } from '@/services/AuthorizationService'
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      form: {
        email: '',
        password: null,
        confirmPassword: null,
        street: '',
        city: '',
        houseNumber: '',
        postcode: '',
        tel: '',
        firstname: '',
        lastname: ''
      },
      registering: false,
      formFilled: false,
      processing: false,
      result: null,
      subUser: {},
      breadCrumbs: [
        { page: 'index', title: 'Uitleendienst' },
        { page: 'account', title: 'Je account' }
      ]
    }
  },
  computed: {
    subUsers: function() {
      return this.$store.getters.accounts
    },
    passwordConfirmState: function() {
      if (this.form.password != '' && this.form.password != null) {
        if (this.form.password == this.form.confirmPassword) {
          return true
        }
        return false
      }
      return null
    },
    isKeyUser: function() {
      return this.$store.getters.user.RoleName === 'portal keyuser'
    },
    editingSelf: function() {
      return this.$store.getters.user.UserEmail === this.subUser.Email
    },
    warning: function() {
      // if (this.$store.getters.user.UserEmail === this.form.email)
      //   return 'Als je email of wachtwoord wijzigt, wijzigt deze ook mee voor eventuele andere profielen onder je gebruikersnaam'
      if (this.result && this.result !== 'OK') {
        return 'Deze contactpersoon kan niet worden verwijderd omdat er nog 1 of meerdere openstaande reserveringen aan dit profiel gelinkt zijn.'
      }
      return null
    }
  },
  async created() {
    this.subUser = this.subUsers.find(obj => {
      return obj.UserName === this.$route.params.username
    })
    this.form = {
      email: this.subUser.Email,
      street: this.subUser.CUST_Street,
      city: this.subUser.CUST_City,
      houseNumber: this.subUser.CUST_HouseNumber,
      postcode: this.subUser.CUST_ZipCode,
      tel: this.subUser.MobilePhone,
      firstname: this.subUser.FirstName,
      lastname: this.subUser.LastName
    }
  },
  metaInfo: function() {
    return {
      title: `Gegevens ${this.subUser.FirstName} ${this.subUser.LastName} bewerken`,
      titleTemplate: '%s - Vlaams-Brabant Uitleendienst'
    }
  },
  methods: {
    deleteContact: async function() {
      const confirmDelete = confirm(
        `Weet je zeker dat je contactpersoon ${this.subUser.FirstName} ${this.subUser.LastName} wilt verwijderen?`
      )

      if (!confirmDelete) return
      const result = await deleteSub({
        subUserName: this.subUser.Email,
        subUserContactID: this.subUser.ContactID
      })

      this.result = result.data
      //this.$router.push({ name: 'account' })
    },
    updateSub: async function() {
      this.processing = true
      // if (Object.values(this.form).some(x => x !== null && x !== '')) {
      //   alert('not all fields are filled')
      // }
      modifySub({
        email: this.form.email,
        password: this.form.password,
        confirmPassword: this.form.confirmPassword,
        street: this.form.street,
        city: this.form.city,
        houseNumber: this.form.houseNumber,
        postcode: this.form.postcode,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        contactID: this.subUser.ContactID,
        userName: this.subUser.UserName,
        tel: this.form.tel
      })
      this.$router.push({ name: 'account' })
    }
  }
}
</script>

<style></style>
